div.views-home {
  width: 70vw;
  //height: 70vw;
  max-width: 1024px;
  //background: green;
  margin-left: auto;
  margin-right: auto;
  //   background-image: url(/images/reflections-01.png);
  //   background-repeat: no-repeat;
  //   background-size: cover;

  padding-bottom: 30px;

  img.reflections-standard {
      width: 100%;
  }
  img.reflections-mobile {
    display: none;
  }
}

// @media (min-width: 1024px) {
//     div.views-home {
//       width: 90vw;
//       height: 100vw;
//       background-image: url(/images/reflections-02.png);
//       background-repeat: no-repeat;
//       background-size: cover;
//     }
//   }

@media (max-width: 699px) {
  div.views-home {
    width: 70vw;
    width: 100%;
    height: initial;
    // width: 90vw;
    // height: 100vw;
    // background-image: url(/images/reflections-02.png);
    // background-repeat: no-repeat;
    // background-size: cover;
    img.reflections-standard {
        display: none;
    }
    img.reflections-mobile {
      display: inline;
      width: 100%;
    }
  }
}


div.views-one {    
    img.reflections-standard {
        max-width: 800px;
    }
  }

  
  @media (max-width: 699px) {
    div.views-one {      
      img.reflections-standard {
          display: inline;
      }
    }
  }
  