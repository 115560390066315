footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    //padding: 30px;
    margin-bottom: 30px;
    margin: 30px 0;
    text-align: center;
    //border-top: 1px solid #fcfce6;
    //background: url(/images/background-01.png) no-repeat center center fixed;
    //background-size: cover;
    //min-height: 200px;

    div.background {
        background: url(/images/the-tree.png) no-repeat center;
        background-size: contain;
        opacity: 0.1;
        //background: pink;
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
    }
    div.sayings {
        opacity: 1;
    }
    h2 {
        font-size: 1.5rem;
    }
    a,
    a:hover {
        color: #333;
        text-decoration: none;
    }
}

@media (max-width: 699px) {
    footer {
        position: relative;
        left: initial;
        bottom: initial;
        width: initial;
        padding: 10px;
    }
}
