@import "css/colors"; // assuming a styles directory under src/, where _colors.scss partial file exists.
@import "css/fonts"; // assuming a styles directory under src/, where _colors.scss partial file exists.
@import "css/bottom/header";
@import "css/bottom/footer";
@import "css/bottom/reflections";
@import "css/bottom/views-home";
@import "css/bottom/views-reflections";

.App {
  text-align: center;
  padding-bottom: 50px;
}

html,
body {
  font-family: "Cormorant Garamond", serif;        
  //font-family: 'Spartan', sans-serif;

  background-color: #fcfce6;
  //background-image: url(/images/background-01.png);
  background: url(/images/background-01.png) no-repeat center center fixed;
  //   background-repeat: no-repeat;
  background-size: cover;  
}

* {
  box-sizing: border-box;
}
