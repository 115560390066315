

div.reflections {
  //width: 40vw;
  //height: 40vw;
  width: 100%;
  height: 100%;
  //background: blue;
  position: relative;

  div.row-middle-mobile {
    display: none;
  }

  div.row-top,
  div.row-bottom {
    display: flex;
    //background: yellowgreen;
    width: 100%;
    height: 50%;

    > div {
      width: 50%;
      //background: yellow;
    }
  }
  div.reflection {
    //border: 1px solid #333;
    width: 100%;
    height: 100%;
    //background: pink;
    text-align: center;
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 2rem;

    // .symbol {
    //   font-family: "Cormorant Garamond", serif;
    //   ////font-family: "La Belle Aurore", cursive;
    //   ////font-family: "Merienda", cursive;
    //   ////font-family: "Neucha", cursive;
    //   ////font-family: "Itim", cursive;
    //   font-family: "Delius", cursive;
    //   //font-family: "Aladin", cursive;
    //   font-weight: bold;
    //   text-align: center;
    // }
    // .message {
    //   font-family: "Cormorant Garamond", serif;
    //   font-family: "La Belle Aurore", cursive;
    //   //font-family: "Itim", cursive;
    //   //font-family: "Delius", cursive;

    //   //font-weight: bold;
    // }
  }
  div.row-middle {
    position: absolute;
    top: 35%;
    left: 35%;
    //background: red;
    height: 30%;
    width: 30%;
    > div {
      height: 100%;      
    }
  }
}


@media (max-width: 699px) {
    div.reflections {
        margin-top: 30px;
        width: initial;
        height: initial;
      div.row-top,
      div.row-bottom,
      div.row-middle-mobile
       {
        display: block !important;
        width: initial;
        height: auto;
        > div {
          width: auto;
          height: auto;
        }
      }

      div.row-middle {
        display: none;
      }
    }
  }