header {
    text-align: center;
    padding: 20px 0 40px 0;

    h1 {
        margin: 0;
        font-size: 5rem;
        //font-family: "Cormorant Garamond", serif;
        //font-family: "Palanquin", sans-serif;
    }

    a,
    a:hover {
        color: #333;
        text-decoration: none;
    }

    div.reflection-symbols {
        font-size: 1.5rem;
    }
}


@media (max-width: 699px) {
    header {
        padding: 20px 0 10px 0;

        h1 {
            font-size: 10vw;
        }
    }
}

@media (max-width: 499px) {
    header {
        padding: 20px 0 0 0;
    }
}