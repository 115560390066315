div.views-reflections {
    width: 70vw;
    height: 70vw;
    //background: green;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 699px) {
    div.views-reflections {
    }
}

div.views-three {
    margin-top: 20px;
}

div.views-one {
    margin-top: 20px;
}
